import { useState } from "react";
import { Flex, Text, Button } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react'; 
import { Link } from "react-router-dom";

import { Header } from "../includes";
import { useDuaa } from "../hooks";
import { tableTheme } from "../includes/theme";


export default function Duaa() {
	const { data } = useDuaa();

    const [colDefs, setColDefs] = useState([
        { 
        	field: "id", 
        	headerName: "ID", 
        	flex: 1
        },
        { 
        	field: "title", 
        	filter: true,
        	flex: 3 
        },
        { 
        	field: "description", 
        	filter: true,
        	flex: 3 
        },
        { 
        	field: "type", 
        	filter: true,
        	flex: 1 
        },
        { 
        	field: "totalVerses", 
        	filter: true,
        	flex: 1 
        },
        { 
        	field: "active", 
        	filter: true,
        	flex: 1 
        },
        {
        	field: 'button',
        	headerName: "",
        	flex: 2,
        	cellRenderer: ({ data }) => (
        		<Flex flex="1" align="center" justify="flex-end" h="100%">
        			<Button as={Link} to={`/duaa/${data.id}`} variant="outline" fontWeight="500" p="7px 12px">Edit</Button>
        		</Flex>
        	)
        }
    ]);

	return (
		<Flex flex="1" direction="column" h={window.innerHeight} w="100%">
			<Header />
			<Flex flex="1" direction="column" p="30px 40px" w="100%">
				<Flex align="center" justify="center" mb="10px">
					<Flex flex="1" direction="column">
						<Text mb="7px" variant="title">Duaa and Ziyarat</Text>
					</Flex>
				</Flex>

			    <Flex flex="1" borderRadius="10px" w="100%" direction="column">
			        <AgGridReact
			            rowData={data}
			            theme={tableTheme}
			            columnDefs={colDefs}
			            
			            pagination
			        />
			    </Flex>
			</Flex>
		</Flex>
	)
}



