import { useState, useEffect } from "react";
import { Flex, Text, Image, Button } from '@chakra-ui/react';
import { NavLink, useNavigate } from "react-router-dom";
import LogoIcon from "../assets/logo.png"
import Parse from "parse";
	
import { useLogoutUser } from "../hooks";

export function Header(){
	const user = Parse.User.current();

	const { mutateAsync } = useLogoutUser();

	async function handleLogout() {
		await mutateAsync();
		window.location.reload();
	}

	if (!user) {
		return null
	}

	const role = user.get('role');

	return (
		<Flex direction="column">
			<Flex bg="#f7f8fa" h="45px" borderBottom="1px solid" borderBottomColor="light" p="0 40px" align="center">
				<Flex align="center" flex="1">
					<Image
						src={LogoIcon}
						w="23px"
						borderRadius="30px"
					/>
					<Text variant="title" fontWeight="500" fontSize="14px" ml="7px">ShiaCircle</Text>
				</Flex>
				<Flex onClick={handleLogout} cursor="pointer" color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
					<Text fontSize="13px" color="default">Logout</Text>
				</Flex>
			</Flex>
			<Flex borderBottom="1px solid" borderBottomColor="light" h="40px" p="4px 40px" align="center" flex="1">
				{
					role == "editor" ?
					<>
						<Flex as={NavLink} to="/questionnaires" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Questionnaires</Text>
						</Flex>
					</>
					:
					role == "admin" ?
					<>
						<Flex as={NavLink} to="/" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Home</Text>
						</Flex>
						<Flex as={NavLink} to="/questionnaires" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Questionnaires</Text>
						</Flex>
						<Flex as={NavLink} to="/videos" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Videos</Text>
						</Flex>
						<Flex as={NavLink} to="/quran" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Quran</Text>
						</Flex>
						<Flex as={NavLink} to="/music" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Music</Text>
						</Flex>
						
						<Flex as={NavLink} to="/randomize-questions" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Randomize</Text>
						</Flex>

						<Flex as={NavLink} to="/duaa" _activeLink={{ bg:"primary", color:"white", _hover:{bg:"primary" }}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Duaa</Text>
						</Flex>

						<Flex as={NavLink} to="/push-notifications" _activeLink={{bg:"primary", color:"white", _hover:{bg:"primary"}}} color="dark" bg="white" p="4px 10px" borderRadius="30px" mr="5px" transition="0.3s all" _hover={{bg:"light"}}>
							<Text fontSize="13px" color="default">Push Notifications</Text>
						</Flex>
					</>
					:
					null
				}
			</Flex>
		</Flex>
	)
}


