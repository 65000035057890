import { useMutation, useQuery, useQueryClient } from 'react-query';
import { supabase } from '../utils'
import { nanoid } from "nanoid";
import { marked } from 'marked';
import { HTMLToJSON } from 'html-to-json-parser';

export const useDuaa = () => {
	return useQuery(['duaa-ziyarat'],
		async () => {
			const { data } = await supabase.from('DuaaZiyara').select()
			return data
		},
		{}
	)
}

export const useDuaaContent = ({ id }) => {
	return useQuery(['duaa-ziyarat', id], 
		async () => {
			const { data } = await supabase.from('DuaaZiyaraContent').select('id, content, duaa_ziyara_id(*), old_content_uri, sound_id(*, reciter_id)').eq("duaa_ziyara_id", id)
			
			let elem;
			if (data.length > 0) {
				elem = data[0]

				elem.content.map((cont, i) => {
					cont.id = nanoid()
				})

				let response = await fetch(elem.old_content_uri);
				let responseJson = await response.json();

				const html = marked(responseJson.content);
				let json = await HTMLToJSON("<div>" + html + "</div>", true);

				let parsedJSON = JSON.parse(json).content;

				let combinedSections = [];
				let currentSection = { id:nanoid(), arabic:"", english: "", arglish: "" };

				parsedJSON.map(el => {
					if (el.type) {
						if (el.type == "hr") {
							combinedSections.push(currentSection);
							currentSection = { id: nanoid(), arabic: "", english: "", arglish: "" };
						} else {
					    	if (el.type === 'h2') {
					    		currentSection.arabic = el.content ? el.content.join(" ") : "";
					    	} else if (el.type === 'h3') {
					    		currentSection.english = el.content ? el.content.join(" ") : "";
					    	} else if (el.type === 'h4') {
					    		currentSection.arglish = el.content ? el.content.join(" ") : "";
					      	}
						}
					}
				})

				elem.oldContent = combinedSections
			}

			return elem
		},
		{}
	)
}

export const useReciters = () => {
	return useQuery(['reciters'], 
		async () => {
			const { data } = await supabase.from('Reciters').select('id, name');
			return data;
		},
		{}
	)
}


export const useEditDuaaContent = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		
		const { ziyaraId, contentId, soundId, title, description, slug, bgColor, content, isActive, reciter, source, requiresSound } = data;

		// DuaaZiyara
		let { error } = await supabase
			.from('DuaaZiyara')
			.update({ 
				title: title.english,
				title_arabic: title.arabic,
				title_persian: title.persian,
				description: description.english,
				description_arabic: description.arabic,
				description_persian: description.persian,
				bgColor: bgColor,
				slug: slug,
				source: source,
				totalVerses: content.length,
				active: isActive,
				requiresSound
			})
			.eq('id', ziyaraId)

		if (error) {
			throw new Error(error.message);
			return
		}

		// DuaaZiyaraContent

		let _content = [...content];

		_content.map(c => {
			delete c.id;
		})

		let { error: error2 } = await supabase
			.from('DuaaZiyaraContent')
			.update({ 
				content: _content
			})
			.eq('id', contentId)		

		if (error2) {
			throw new Error(error2.message);
			return
		}

		// DuaaZiyaraSounds
		let { error: error3 } = await supabase
			.from('DuaaZiyaraSounds')
			.update({ 
				reciter_id: reciter
			})
			.eq('id', soundId)		

		if (error3) {
			throw new Error(error3.message);
			return
		}


		
		










		queryClient.refetchQueries(['duaa-ziyarat', ziyaraId]);
	}, {
		...options,
	})
}





