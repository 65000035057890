import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";
import { supabase } from '../utils'

export const useAddMultiLanguageGroup = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { live, languageGroup, type, order } = data;

		const { data: newData } = await supabase.from('MultiLangVideoGroup').insert({
			live,
			type,
			order
		}).select()
		const newDataRowId = newData[0].id;

		await Promise.all(Object.keys(languageGroup).map(async key => {
			if (type == "video") {
				await supabase
					.from('Videos')
					.update({ 
						multiLangGroup: newDataRowId
					})
					.eq('id', languageGroup[key])				

			} else {
				await supabase
					.from('VideoAlbums')
					.update({ 
						multiLangGroup: newDataRowId
					})
					.eq('id', languageGroup[key])	
			}
		}))

		queryClient.refetchQueries(['videos']);
		return "done";	
	}, {
		...options,
	})
}


export const useDetachGroup = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		let { type, id, multiLangGroup } = data;

		if (type == "video") {
			await supabase
				.from('Videos')
				.update({ 
					multiLangGroup: null
				})
				.eq('id', id)				
		} else {
			await supabase
				.from('VideoAlbums')
				.update({ 
					multiLangGroup: null
				})
				.eq('id', id)
		}

		const { data: albums } = await supabase.from('VideoAlbums').select('*').eq('multiLangGroup', multiLangGroup.id);
		const { data: videos } = await supabase.from('Videos').select('*').eq('multiLangGroup', multiLangGroup.id);

		if (albums.length == 0 && videos.length == 0) {
			await supabase
				.from('MultiLangVideoGroup')
				.delete()
				.eq('id', multiLangGroup.id)				
		}

		queryClient.refetchQueries(['videos']);

		return
	}, {
		...options,
	})
}
















