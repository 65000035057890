import { useEffect } from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Parse from 'parse';
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AllCommunityModule, ModuleRegistry, RowDragModule } from 'ag-grid-community'; 

import { Fonts, theme } from "./includes";
import { QuestionnaireProvider } from "./includes/contexts/questionnaire";

import Home from "./layouts/home";
import Users from "./layouts/users";
import Videos from "./layouts/videos";
import VideoDetails from "./layouts/videos/video-details";
import AlbumDetails from "./layouts/videos/album-details";
import MultiLangDetails from "./layouts/videos/multi-lang-details";

import TikTokVideos from "./layouts/tik-tok-videos";
import Quran from "./layouts/quran";
import Music from "./layouts/music";
import Search from "./layouts/search";
import Duaa from "./layouts/duaa";
import DuaaDetails from "./layouts/duaa-details";

import Login from "./layouts/user/login";
import ForgotPassword from "./layouts/user/forgot-password";
import Questionnaires from "./layouts/questionnaires";
import QuestionnaireDetails from "./layouts/questionnaire-details";
import SectionDetails from "./layouts/section-details";
import RandomizeQuestions from "./layouts/randomize-questions";
import PushNotifications from "./layouts/push-notifications";

Parse.initialize(process.env.REACT_APP_SERVER_APP_ID, process.env.REACT_APP_SERVER_JS_KEY);
Parse.serverURL = process.env.REACT_APP_SERVER_URL;
ModuleRegistry.registerModules([AllCommunityModule, RowDragModule]);

function App(){
    const navigate = useNavigate();
    const user = Parse.User.current();

    useEffect(async () => {
        if (!Parse.User.current()) {
            navigate('/login');
        } else {
            try {
                await Parse.User.current().fetch();
            }
            catch (error) {
                if (error.code == 209) {
                    Parse.User.logOut();
                    navigate('/login')
                }
            }
        }
    },[])

    return (
        <Routes>
            {
                user && user.get('role') == "editor" ?
                <>
                    <Route exact="true" path="/questionnaires" element={<Questionnaires />} />
                    <Route exact="true" path="/questionnaires/:id" element={<QuestionnaireDetails />} />
                    <Route exact="true" path="/questionnaires/:id/sections/:sectionID" element={<SectionDetails />} />
                    <Route exact="true" path="/questionnaires/:id/sections/:sectionID/lessons/:lessonID" element={<SectionDetails />} />
                </>
                :
                user && user.get('role') == "admin" ?
                <>
                    <Route exact="true" path="/" element={<Home />} />
                    <Route exact="true" path="/users" element={<Users />} />
                    <Route exact="true" path="/videos" element={<Videos />} />
                    <Route exact="true" path="/video/:id" element={<VideoDetails />} />
                    <Route exact="true" path="/album/:id" element={<AlbumDetails />} />
                    <Route exact="true" path="/multi-lang-group/:id" element={<MultiLangDetails />} />

                    <Route exact="true" path="/duaa" element={<Duaa />} />
                    <Route exact="true" path="/duaa/:id" element={<DuaaDetails />} />

                    <Route exact="true" path="/quran" element={<Quran />} />
                    <Route exact="true" path="/music" element={<Music />} />
                    <Route exact="true" path="/randomize-questions" element={<RandomizeQuestions />} />
                    <Route exact="true" path="/push-notifications" element={<PushNotifications />} />
                    <Route exact="true" path="/search" element={<Search />} />
                    <Route exact="true" path="/tiktok-videos" element={<TikTokVideos />} />

                    <Route exact="true" path="/questionnaires" element={<Questionnaires />} />
                    <Route exact="true" path="/questionnaires/:id" element={<QuestionnaireDetails />} />
                    <Route exact="true" path="/questionnaires/:id/sections/:sectionID" element={<SectionDetails />} />
                    <Route exact="true" path="/questionnaires/:id/sections/:sectionID/lessons/:lessonID" element={<SectionDetails />} />
                </>
                :
                null
            }

            <Route exact="true" path="/login" element={<Login />} />
            <Route exact="true" path="/forgot-password" element={<ForgotPassword />} />
        </Routes> 
    )
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
        }
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider theme={theme}>
        <Fonts />
        <BrowserRouter>
           <QueryClientProvider client={queryClient}>
                <QuestionnaireProvider>
                    <App />
                </QuestionnaireProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </ChakraProvider>
);



