import React, { useState, useEffect, useRef } from "react";
import { Flex, Text, Button, Input, Textarea, Select, Switch, Tag, Image, useToast } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react'; 

import { Header, AddVideoModal } from "../../includes";
import { useVideos, useCategories, useEditVideoOrder, useEditVideos, useVideoAlbums, useEditVideoAlbum, useDeleteAlbum } from "../../hooks";
import { isEmpty } from "../../utils";
import { tableTheme } from "../../includes/theme";

export default function AlbumDetails({ prefetchedData }) {
	const { id } = useParams();
	const { data: videos, isLoading } = useVideos();
	const { data: categories } = useCategories('video');
	const { data: albums } = useVideoAlbums();
	const { mutateAsync: onEditAlbum } = useEditVideoAlbum();
	const { mutateAsync: onDeleteAlbum } = useDeleteAlbum();

	const [ data, setData] = useState();
	const navigate = useNavigate();
	const toast = useToast();

	const [loading, setLoading] = useState(false);
	const [addVideo, setAddVideo] = useState(false);

	useEffect(() => {
		if (isLoading == false) {
			if (prefetchedData) {
				let _data = {...prefetchedData}
				_data.videos.sort((a, b) => a.order - b.order);
				setData(_data);
				return;
			}

			let _data = {...videos.filter(e => e.id == id)[0]};
			_data.videos.sort((a, b) => a.order - b.order);

			setData(_data)
		}
	},[isLoading])

	function handleTextChange(type, value) {
		let _data = {...data};
		_data[type] = value;
		setData(_data);
	}

	async function handleSubmit() {
		const errors = validate(data);
		if (errors.length > 0) {
			toast({
				status:"error",
				title:errors.join(", "),
				position: 'top',
			})
			return;
		}
		setLoading(true);
		try {
			await onEditAlbum(data);
			toast({
				status:"success",
				title: "Album saved succesfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title: error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function handleDelete() {
		if (window.confirm("Are you sure?") == true) {
			setLoading(true);
			await onDeleteAlbum({
				id: data.id
			})
			toast({
				status:"success",
				title: "Album deleted succesfully!",
				position: 'top',
			})
			navigate('/videos');
			setLoading(false);
		}
	}

	if (!data || !categories) {
		return
	}

	return (
		<Flex flex="1" direction="column" w="100%" h={window.innerHeight}>
			<Header />
			<Flex p="12px 20px" borderBottom="1px solid" borderBottomColor="light">
				<Flex as={Link} to="/videos" fontSize="12px" align="center" color="primary">
					<i className="fa-solid fa-arrow-left"></i>
					<Text pl="5px" variant="link">Back to Videos</Text>
				</Flex>
				<Flex align="center" justify="center" flex="1">
				</Flex>
				<Text textAlign="right" variant="subtitle" fontSize="10px">{id}</Text>
			</Flex>	

			<Flex flex="1" direction="row" w="100%">
				<Flex w="400px" h="100%" p="10px" borderRight="1px solid #f3f3f3" direction="column">
					<Flex direction="row" w="100%" mb="15px">
						<Text flex="1" mr="4px" variant="subtitle">Type</Text>
						<Tag>{data.type}</Tag>
					</Flex>

					{
						data.videos.length > 0 && (
							<Flex direction="column" w="100%" mb="15px">
								<Flex>
									<Text mr="4px" variant="subtitle">Cover Image</Text>
									<Text color="red">*</Text>
								</Flex>
								<Flex cursor="pointer" direction="column" mt="5px" borderRadius="10px" h="200px" border="1px solid" borderColor="light" align="center" justify="center" overflow="hidden">
									<Image src={data.videos[0].image} w="100%" h="100%" objectFit="cover" objectPosition="center" />
								</Flex>
							</Flex>
						)
					}

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Title</Text>
							<Text color="red">*</Text>
						</Flex>
						<Input
							mt="3px"
							value={data.title}
							onChange={(e) => handleTextChange('title', e.target.value)}
							fontWeight="500"
							h="45px"
							p="10px"
							fontSize="15px"
						/>
					</Flex>

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Description</Text>
							<Text color="red">*</Text>
						</Flex>
						<Textarea
							mt="3px"
							value={data.description}
							onChange={(e) => handleTextChange('description', e.target.value)}
							fontWeight="500"
							p="10px"
							fontSize="15px"
							rows={7}
						/>
					</Flex>

					<Flex direction="row" mb="15px">
						<Flex direction="column" w="100%" flex="1" mr="10px">
							<Flex>
								<Text mr="4px" variant="subtitle">Category</Text>
								<Text color="red">*</Text>
							</Flex>
							<Select value={data.category} onChange={(e) => handleTextChange('category', e.target.value)} fontWeight="500" mt="3px">
								{
									categories.map(cat => (
										<option key={cat.id} value={cat.id}>{cat.fullTitle['english']}</option>
									))
								}
							</Select>
						</Flex>
						<Flex direction="column" w="100%" flex="1">
							<Flex>
								<Text mr="4px" variant="subtitle">Language</Text>
								<Text color="red">*</Text>
							</Flex>
							<Select value={data.language} onChange={(e) => handleTextChange('language', e.target.value)} fontWeight="500" mt="3px">
								<option value="english">English</option>
								<option value="arabic">Arabic</option>
								<option value="persian">Persian</option>
								<option value="mixed">Mixed</option>							
							</Select>
						</Flex>	
					</Flex>

					<Flex mt="20px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Total Videos</Text>
						<Text variant="subtitle">{data.videos.length}</Text>
						
					</Flex>		

					<Flex mt="20px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Live</Text>
						<Switch isChecked={data.live} onChange={data.videos.length == 0 ? null : (e) => handleTextChange('live', e.target.checked)} colorScheme="green" size='md' />
					</Flex>		

					<Flex flex="1"></Flex>
					<Flex zIndex={2} w="100%" borderTop="1px solid" borderTopColor="light" pt="15px">
						<Button onClick={handleDelete} flex='1' variant="error" p="13px 15px" mr="10px" fontSize="13px" w="100%">Delete</Button>
						<Button isLoading={loading} flex="2" onClick={handleSubmit} variant="secondary" p="13px 15px" fontSize="13px" >Save details</Button>
					</Flex>	
				</Flex>

				<Flex flex="1" p="15px" align="flex-start" direction="column">
					<Flex w="100%" align="center" justify="center" mb="10px">
						<Flex flex="1" direction="column">
							<Text mb="7px" variant="title">Videos</Text>
						</Flex>
						<Button onClick={() => setAddVideo(true)} variant="primary" borderRadius="30px" fontSize="14px" p="12px 15px">Add videos</Button>
					</Flex>

					<VideoTable videos={data.videos} />
				</Flex>
			</Flex>

			{
				addVideo && <AddVideoModal albums={albums} chosenAlbum={data.id} totalVideos={data.videos.length} onClose={() => setAddVideo(false)} />
			}

		</Flex>
	)
}


function VideoTable({ videos }) {
	const gridRef = useRef();
	const { mutateAsync: updateOrder } = useEditVideoOrder();
	const { mutateAsync: updateVideos } = useEditVideos();

	const [rowData, setRowData] = useState(videos);
    const [colDefs, setColDefs] = useState([
        { 
        	field: "title", 
        	headerName: "Name",
        	filter: true,
        	editable: true,
        	cellEditor: 'agLargeTextCellEditor',
        	cellEditorPopup: true,
	        cellEditorParams: {
	            maxLength: 1000
	        },
        	rowDrag: true,
        	flex: 3,
        	cellRenderer: ({ data }) => {
        		return (
					<Flex h="100%" align="center">
						<Image src={data.image} w="63px" h="35px" alt="Video Image" borderRadius="5px" />
						<Text pl="13px" fontWeight="400" fontSize="15px">{data.title}</Text>
					</Flex>
        		)
        	}
        },
        { 
        	field: "description", 
        	filter: true,
        	editable: true,
        	cellEditor: 'agLargeTextCellEditor',
        	cellEditorPopup: true,
	        cellEditorParams: {
	            maxLength: 1000
	        },
        	flex: 3 
        },
        { 
        	field: "videoID", 
        	filter: true,
        	editable: true,
        	flex: 2 
        },
        { 
        	field: "live", 
        	filter: true,
        	editable: true,
        	flex: 1 
        },
        { 
        	field: "order", 
        	filter: true,
        	flex: 1 
        }
    ]);

    function onRowDragEnd() {
    	if (!gridRef.current) return;

    	const gridApi = gridRef.current.api;
        const newRowData = [];

        gridApi.forEachNode((node, index) => {
            newRowData.push({ ...node.data, order: index + 1 });
        });
        setRowData(newRowData);
        updateOrder(newRowData);
    }
    function onCellValueChanged() {
    	if (!gridRef.current) return;
    	const gridApi = gridRef.current.api;
        const newRowData = [];

        gridApi.forEachNode((node, index) => {
            newRowData.push({ ...node.data, order: index + 1 });
        });
        setRowData(newRowData);
        updateVideos(newRowData);
    }


	return (
	    <Flex flex="1" borderRadius="10px" w="100%" direction="column">
	        <AgGridReact
	        	ref={gridRef}
	            rowData={rowData}
	            theme={tableTheme}
	            columnDefs={colDefs}
	            rowDragManaged
	            onRowDragEnd={onRowDragEnd}
	            onCellValueChanged={onCellValueChanged}
	        />
	    </Flex>
	)
}


function validate({ title, description, category, language }) {
	let errors = [];
	if (isEmpty(title)) {
		errors.push('Title')
	}
	if (isEmpty(description)) {
		errors.push('Description')
	}
	if (isEmpty(category)) {
		errors.push('Category')
	}
	if (isEmpty(language)) {
		errors.push('Language')
	}
	return errors;
}















