import React, { useState, useEffect, Component, useRef } from "react";
import { Flex, Text, Button, Input, Textarea, Select, Switch, Tag, Image, useToast } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

import { Header } from "../../includes";
import { useVideos, useCategories, useEditVideo, useDeleteVideo, useDetachGroup } from "../../hooks";
import { isEmpty } from "../../utils";

export default function VideoDetails({ prefetchedData }) {
	const { id } = useParams();
	const { data: videos, isLoading } = useVideos();
	const { data: categories } = useCategories('video');
	const { mutateAsync: onEditVideo } = useEditVideo();
	const { mutateAsync: onDeleteVideo } = useDeleteVideo();
	const { mutateAsync: detachGroup } = useDetachGroup();

	const navigate = useNavigate();
	const toast = useToast();

	const [ data, setData] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isLoading == false) {
			if (prefetchedData) {
				setData(prefetchedData);
				return;
			}
			setData(videos.filter(e => e.id == id)[0])
		}
	},[isLoading])

	function handleTextChange(type, value) {
		let _data = {...data};
		_data[type] = value;

		if (type == "videoID") {
			_data['image'] = "https://i.ytimg.com/vi/" + value +"/hqdefault.jpg"
		}

		setData(_data);
	}

	async function handleSubmit() {
		const errors = validate(data);
		if (errors.length > 0) {
			toast({
				status:"error",
				title:errors.join(", "),
				position: 'top',
			})
			return;
		}

		setLoading(true);
		try {
			await onEditVideo(data);
			toast({
				status:"success",
				title: "Video details saved succesfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title: error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function handleDelete() {
		if (window.confirm("Are you sure?") == true) {
			setLoading(true);
			await onDeleteVideo({
				id: data.id
			})
			toast({
				status:"success",
				title: "Video deleted succesfully!",
				position: 'top',
			})
			navigate('/videos');

			setLoading(false);
		}
	}

	async function onDetachGroup() {
		if (window.confirm("Are you sure?") == true) {
			await detachGroup({
				id: data.id,
				type: "video",
				"multiLangGroup": data.multiLangGroup
			})
			navigate('/videos');
			toast({
				status:"success",
				title: "Video detached succesfully!",
				position: 'top',
			})			

		}
	}

	if (!data || !categories) {
		return
	}

	return (
		<Flex flex="1" direction="column" w="100%" h={window.innerHeight}>
			<Header />

			<Flex p="12px 20px" borderBottom="1px solid" borderBottomColor="light">
				<Flex as={Link} to="/videos" fontSize="12px" align="center" color="primary">
					<i className="fa-solid fa-arrow-left"></i>
					<Text pl="5px" variant="link">Back to Videos</Text>
				</Flex>
				<Flex align="center" justify="center" flex="1">
				</Flex>
				<Text textAlign="right" variant="subtitle" fontSize="10px">{id}</Text>
			</Flex>	

			<Flex flex="1" direction="row" w="100%">
				<Flex w="400px" h="100%" p="10px" borderRight="1px solid #f3f3f3" direction="column">
					<Flex direction="row" w="100%" mb="15px">
						<Text flex="1" mr="4px" variant="subtitle">Type</Text>
						<Tag>{data.type}</Tag>
					</Flex>

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Image</Text>
							<Text color="red">*</Text>
						</Flex>
						<Flex cursor="pointer" direction="column" mt="5px" borderRadius="10px" h="200px" border="1px solid" borderColor="light" align="center" justify="center" overflow="hidden">
							<Image src={data.image} w="100%" h="100%" objectFit="cover" objectPosition="center" />
						</Flex>
					</Flex>

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Title</Text>
							<Text color="red">*</Text>
						</Flex>
						<Input
							mt="3px"
							value={data.title}
							onChange={(e) => handleTextChange('title', e.target.value)}
							fontWeight="500"
							h="45px"
							p="10px"
							fontSize="15px"
						/>
					</Flex>

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Author</Text>
							<Text color="red">*</Text>
						</Flex>
						<Input
							mt="3px"
							value={data.author}
							onChange={(e) => handleTextChange('author', e.target.value)}
							fontWeight="500"
							h="45px"
							p="10px"
							fontSize="15px"
						/>
					</Flex>	

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Description</Text>
							<Text color="red">*</Text>
						</Flex>
						<Textarea
							mt="3px"
							value={data.description}
							onChange={(e) => handleTextChange('description', e.target.value)}
							fontWeight="500"
							p="10px"
							fontSize="15px"
							rows={7}
						/>
					</Flex>

					<Flex direction="row" mb="15px">
						<Flex direction="column" w="100%" flex="1" mr="10px">
							<Flex>
								<Text mr="4px" variant="subtitle">Category</Text>
								<Text color="red">*</Text>
							</Flex>
							<Select value={data.category} onChange={(e) => handleTextChange('category', e.target.value)} fontWeight="500" mt="3px">
								{
									categories.map(cat => (
										<option key={cat.id} value={cat.id}>{cat.fullTitle['english']}</option>
									))
								}
							</Select>
						</Flex>

						<Flex direction="column" w="100%" flex="1">
							<Flex>
								<Text mr="4px" variant="subtitle">Language</Text>
								<Text color="red">*</Text>
							</Flex>
							<Select value={data.language} onChange={(e) => handleTextChange('language', e.target.value)} fontWeight="500" mt="3px">
								<option value="english">English</option>
								<option value="arabic">Arabic</option>
								<option value="persian">Persian</option>
								<option value="mixed">Mixed</option>							
							</Select>
						</Flex>	
					</Flex>

					<Flex direction="row" mb="15px">
						<Flex direction="column" w="100%" flex="1" mr="10px">
							<Flex>
								<Text mr="4px" variant="subtitle">Youtube ID</Text>
								<Text color="red">*</Text>
							</Flex>
							<Input
								mt="3px"
								value={data.videoID}
								onChange={(e) => handleTextChange('videoID', e.target.value)}
								fontWeight="500"
								h="45px"
								p="10px"
								fontSize="15px"
							/>
						</Flex>
						<Flex direction="column" w="100%" flex="1">
							<Text mr="4px" variant="subtitle">Youtube Category ID</Text>
							<Input
								mt="3px"
								value={data.youtubeCategoryID}
								onChange={(e) => handleTextChange('youtubeCategoryID', e.target.value)}
								fontWeight="500"
								h="45px"
								p="10px"
								fontSize="15px"
							/>
						</Flex>
					</Flex>

					<Flex mt="20px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Live</Text>
						<Switch isChecked={data.live} onChange={(e) => handleTextChange('live', e.target.checked)} colorScheme="green" size='md' />
					</Flex>

					<Flex mt="20px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Downloaded</Text>
						<Switch isChecked={data.isDownloaded} colorScheme="green" size='md' />
					</Flex>

					<Flex flex="1"></Flex>
					{
						data.multiLangGroup && (
							<Button onClick={onDetachGroup} mb="10px" variant="secondary" p="13px 15px" fontSize="13px" w="100%">Detach from group</Button>
						)
					}
					<Flex zIndex={2} w="100%" borderTop="1px solid" borderTopColor="light" pt="15px">
						<Button onClick={handleDelete} flex='1' variant="error" p="13px 15px" mr="10px" fontSize="13px" w="100%">Delete</Button>
						<Button isLoading={loading} flex="2" onClick={handleSubmit} variant="secondary" p="13px 15px" fontSize="13px" >Save details</Button>
					</Flex>	
				</Flex>

				<Flex flex="1" align="center" justify="center" p="20px">
					<iframe width="100%" height="100%" src={"https://www.youtube.com/embed/" + data.videoID} style={{ borderRadius: 20 }} title="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
				</Flex>
			</Flex>
		</Flex>
	)
}


function validate({ title, image, author, description, category, language, videoID }) {
	let errors = [];

	if (isEmpty(title)) {
		errors.push('Title');
	}
	if (isEmpty(image)) {
		errors.push('Image');
	}
	if (isEmpty(author)) {
		errors.push('Author');
	}
	if (isEmpty(description)) {
		errors.push('Description');
	}
	if (isEmpty(category)) {
		errors.push('Category');
	}
	if (isEmpty(language)) {
		errors.push('Language');
	}
	if (isEmpty(videoID)) {
		errors.push('Video ID');
	}

	return errors;
}












