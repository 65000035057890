import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";
import { supabase } from '../utils'

export const useVideos = (id) => {
	return useQuery(['videos'],
		async () => {
			let VIDEOS = [];

			const { data } = await supabase.from('Videos').select('*, album(*, multiLangGroup(*)), multiLangGroup(*)');
			const { data: albums } = await supabase.from('VideoAlbums').select('*');

			data.map(video => {
				if (video.multiLangGroup) {
					let index1 = VIDEOS.findIndex(e => e.id == video.multiLangGroup.id);
					if (index1 >= 0) {
						if (video.language == "english") {
							VIDEOS[index1].title = video.title;
						}
						VIDEOS[index1].group.push({
							...video,
							type: "video"
						})
					} else {
						VIDEOS.push({
							"id": video.multiLangGroup.id,
							"type": "multiLangGroup",
							"title": video.title,
							"live": video.multiLangGroup.live,
							'order': video.multiLangGroup.order,
							"group":[{
								...video,
								type: "video"
							}]
						})							
					}
					return;
				}

				if (video.album) {
					if (video.album.multiLangGroup) {
						let index1 = VIDEOS.findIndex(e => e.id == video.album.multiLangGroup.id);
						if (index1 >= 0) {
							let albumIndex = VIDEOS[index1].group.findIndex(e => e.id == video.album.id);
							if (albumIndex >= 0) {
								VIDEOS[index1].group[albumIndex].videos.push(video)
							} else {
								if (video.album.language == "english") {
									VIDEOS[index1].title = video.album.title;
								}
								VIDEOS[index1].group.push({
									"id": video.album.id,
									"type": "album",
									"videos": [video],
									"order": video.album.order,
									"language": video.album.language,
									"title": video.album.title,
									"description": video.album.description,
									"category": video.album.category
								})
							}
						} else {
							VIDEOS.push({
								"id": video.album.multiLangGroup.id,
								"type": "multiLangGroup",
								"live": video.album.multiLangGroup.live,
								"order": video.album.multiLangGroup.order,
								"title": video.album.title,
								"group":[{
									"id": video.album.id,
									"type": "album",
									"videos": [video],
									"order": video.album.order,
									"description": video.album.description,
									"language": video.album.language,
									"title": video.album.title,
									"category": video.album.category
								}]
							})	
						}
						return
					}

					let index = VIDEOS.findIndex(e => e.id == video.album.id);
					if (index >= 0) {
						VIDEOS[index].videos.push(video)
					} else {
						VIDEOS.push({
							"id": video.album.id,
							"type": "album",
							"videos": [video],
							"live": video.album.live,
							"order": video.album.order,
							"description": video.album.description,
							"language": video.album.language,
							"title": video.album.title,
							"category": video.album.category
						})
					}
					return;
				} else {
					VIDEOS.push({
						...video,
						type: "video"
					})							
				}
			})

			albums.map(alb => {
				if (alb.multiLangGroup) {
					return
				}

				const index = VIDEOS.findIndex(e => e.id == alb.id);
				if (index < 0) {
					VIDEOS.push({
						"id": alb.id,
						"type": "album",
						"videos": [],
						"live": alb.live,
						"order": alb.order,
						"description": alb.description,
						"language": alb.language,
						"title": alb.title,
						"category": alb.category
					})
				}
			})

			VIDEOS.sort((a, b) => a.order - b.order);
			VIDEOS.map((video, i) => {
				video.order = i + 1
			})

			return VIDEOS;
		},
		{}
	)
}

export const useEditVideoOrder = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		try {
			await Promise.all(data.map(async row => {
				if (row.type == "multiLangGroup") {
					await supabase
						.from('MultiLangVideoGroup')
						.update({ 
							order: row.order,
						})
						.eq('id', row.id)
				} else if (row.type == "album") {
					await supabase
						.from('VideoAlbums')
						.update({ 
							order: row.order,
						})
						.eq('id', row.id)
				} else {
					await supabase
						.from('Videos')
						.update({ 
							order: row.order,
						})
						.eq('id', row.id)
				}
			}))

			queryClient.refetchQueries(['videos']);
		}
		catch(error) {
			console.log(error.message)
		}
	}, {
		...options,
	})
}

export const useAddVideo = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		if (!data.embeddedURL) {

			const { data: savedVideo } = await supabase.from('Videos').select('*').eq('videoID', data.videoID);
			if (savedVideo.length > 0) {
				throw new Error('Youtube video has already been added!')
				return
			}

			await supabase.from('Videos').insert({
				"album": data.album,
				"author": data.author,
				"category": data.category,
				"description": data.description,
				"image": data.image,
				"language": data.language,
				"live": data.live,
				"order": data.order,
				"title": data.title,
				"videoID": data.videoID,
				"youtubeCategoryID": data.youtubeCategoryID
			})
			queryClient.refetchQueries(['videos']);			
		}

	}, {
		...options,
	})
}

export const useEditVideo = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		await supabase
			.from('Videos')
			.update({ 
				image: data.image,
				title: data.title,
				author: data.author,
				description: data.description,
				category: data.category,
				language: data.language,
				videoID: data.videoID,
				youtubeCategoryID: data.youtubeCategoryID,
				live: data.live
			})
			.eq('id', data.id)

		queryClient.refetchQueries(['videos']);
	}, {
		...options,
	})
}


export const useEditVideos = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		await Promise.all(data.map(async video => {
			await supabase
				.from('Videos')
				.update({ 
					title: video.title,
					description: video.description,
					live: video.live
				})
				.eq('id', video.id)
		}))
		
		queryClient.refetchQueries(['videos']);
	}, {
		...options,
	})
}

export const useDeleteVideo = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		await supabase
			.from('Videos')
			.delete()
			.eq('id', data.id)		

		queryClient.refetchQueries(['videos']);
	}, {
		...options,
	})
}





