import { Flex, Text } from '@chakra-ui/react';
import { Header } from "../includes";

import { useBasicAnalytics } from "../hooks";
import { comma } from "../utils";

export default function Home() {

	const { data = {} } = useBasicAnalytics();

	return (
		<Flex direction="column">
			<Header />
			
			<Flex direction="row" w="100%" p="25px 40px" pb="0px">
				
				<Flex border="1px solid" borderColor="light" flex="2" h="170px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-user"></i></div>
					</Flex>
					<Flex direction="row" w="100%">
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid " borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Total users</Text>
							<Text fontSize="33px" fontWeight="600">{comma(data.totalUsers) || "..."}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">iOS</Text>
							<Text fontSize="33px" fontWeight="600">{comma(data.totalIOSUsers) || "..."}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Android</Text>
							<Text fontSize="33px" fontWeight="600">{comma(data.totalAndroidUsers) || "..."}</Text>
						</Flex>
					</Flex>
				</Flex>
				<Flex border="1px solid" borderColor="light" flex="2" h="170px" align="center" justify="center" borderRadius="10px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-language"></i></div>
					</Flex>
					<Flex direction="row" w="100%">
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid " borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">English users</Text>
							<Text fontSize="33px" fontWeight="600">{comma(data.totalEnglishUsers) || "..."}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Arabic users</Text>
							<Text fontSize="33px" fontWeight="600">{comma(data.totalArabicUsers) || "..."}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Persian users</Text>
							<Text fontSize="33px" fontWeight="600">{comma(data.totalPersianUsers) || "..."}</Text>
						</Flex>
					</Flex>
				</Flex>				
			</Flex>
			<Flex direction="row" w="100%" p="25px 40px" pb="0px">
				<Flex border="1px solid" borderColor="light" flex="2" h="170px" align="center" justify="center" borderRadius="10px" mr="15px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-money-check-dollar"></i></div>
					</Flex>
					<Flex direction="row" w="100%">
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid " borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">New users today</Text>
							<Text fontSize="33px" fontWeight="600">{comma(data.totalUsersToday) || "0"}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Total Revenue</Text>
							<Text fontSize="33px" fontWeight="600">${data.totalRevenue || "0"}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Revenue today</Text>
							<Text fontSize="33px" fontWeight="600">${data.totalRevenueToday || "0"}</Text>
						</Flex>

					</Flex>
				</Flex>
		

				<Flex border="1px solid" borderColor="light" flex="2" h="170px" align="center" justify="center" borderRadius="10px"  direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-question-circle"></i></div>
					</Flex>
					<Flex direction="row" w="100%">
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid " borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Support tickets</Text>
							<Text fontSize="33px" fontWeight="600">{data.totalSupportTickets || "0"}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Total Videos</Text>
							<Text fontSize="33px" fontWeight="600">{data.totalVideos || "..."}</Text>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			<Flex direction="row" w="100%" p="25px 40px" pb="0px">
				<Flex border="1px solid" borderColor="light" flex="2" h="170px" align="center" justify="center" borderRadius="10px" direction="column">
					<Flex mb="15px" w="40px" h="40px" borderRadius="30px" bg="light" color="lightText" align="center" justify="center">
						<div><i className="fa-solid fa-music"></i></div>
					</Flex>
					<Flex direction="row" w="100%">
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid " borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Total Latmiya</Text>
							<Text fontSize="33px" fontWeight="600">{data.totalLatmiya || "0"}</Text>
						</Flex>
						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid" borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Pending Latmiya</Text>
							<Text fontSize="33px" fontWeight="600">{data.totalPendingLatmiya || "0"}</Text>
						</Flex>

						<Flex flex="1" direction="column" align="center" justify="center" borderRight="1px solid " borderRightColor="light">
							<Text variant="subtitle" fontWeight="600">Latmiya Requests</Text>
							<Text fontSize="33px" fontWeight="600">{data.totalLatmiyaRequests || "0"}</Text>
						</Flex>
					</Flex>
				</Flex>
				<Flex flex="1"></Flex>
			</Flex>
		</Flex>
	)
}


