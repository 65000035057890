import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";
import { startOfDay, endOfDay } from "date-fns";

export const useBasicAnalytics = (type) => {
	return useQuery(['basic-analytics'],
		async () => {
			
			let data = {
				totalUsers: 0,
				totalUserAccounts: 0,
				totalLessons:0,
				totalVideos:0,
				totalKhatmat:0,
				totalIOSUsers: 0,
				totalAndroidUsers: 0,
				totalSupportTickets: 0,
				totalEnglishUsers: 0,
				totalArabicUsers: 0,
				totalPersianUsers: 0,
				totalRevenue: 0,
				totalLatmiyaRequests: 0,
				totalPendingLatmiya: 0,
				totalLatmiya: 0,
				totalRevenueToday: 0
			}

			const query1 = new Parse.Query('Notifications');
			data.totalUsers = await query1.count();

			const query2 = new Parse.Query('_User');
			data.totalUserAccounts = await query2.count();

			const query3 = new Parse.Query('Questionnaires');
			query3.equalTo('live', true)
			data.totalLessons = await query3.count();
			
			const query4 = new Parse.Query('Videos');
			query4.equalTo('live', true)
			query4.equalTo('album', null)
			const totalVideos = await query4.count();

			const query5 = new Parse.Query('VideoAlbums');
			query5.equalTo('live', true)
			const totalAlbums = await query5.count();

			const query6 = new Parse.Query('MultiLanguageGroups');
			query6.equalTo('live', true)
			const totalAlbums2 = await query6.count();

			data.totalVideos = totalVideos + totalAlbums + totalAlbums2

			const query8 = new Parse.Query('Notifications');
			query8.equalTo('deviceType', 'ios')
			data.totalIOSUsers = await query8.count();

			const query9 = new Parse.Query('Notifications');
			query9.equalTo('deviceType', 'android')
			data.totalAndroidUsers = await query9.count();

			const query10 = new Parse.Query('Support');
			query10.equalTo('active', true);
			data.totalSupportTickets = await query10.count();


			const query11 = new Parse.Query('Notifications');
			query11.equalTo('language', "english")
			data.totalEnglishUsers = await query11.count();

			const query12 = new Parse.Query('Notifications');
			query12.equalTo('language', "arabic")
			data.totalArabicUsers = await query12.count();
			
			const query13 = new Parse.Query('Notifications');
			query13.equalTo('language', "persian")
			data.totalPersianUsers = await query13.count();

			
			const query14 = new Parse.Query('Notifications');
			query14.greaterThan('createdAt', startOfDay(new Date()))

			data.totalUsersToday = await query14.count();


			const query15 = new Parse.Query('CharityAdLogs');
			query15.select('adRevenue');
			query15.limit(100000);
			const _adLogsData = await query15.find();

			_adLogsData.map(log => {
				data.totalRevenue += log.get('adRevenue')
			})

			data.totalRevenue = data.totalRevenue.toFixed(2);

			const query16 = new Parse.Query('MusicRequests');
			query16.equalTo('active', true);
			data.totalLatmiyaRequests = await query16.count();

			const query17 = new Parse.Query('Musics');
			query17.equalTo('isDownloaded', false);
			data.totalPendingLatmiya = await query17.count();


			const query18 = new Parse.Query('Musics');
			query18.equalTo('isDownloaded', true);
			data.totalLatmiya = await query18.count();


			const query19 = new Parse.Query('CharityAdLogs');
			query19.greaterThan('createdAt', startOfDay(new Date()))
			query19.select('adRevenue');
			query19.limit(100000);
			const _adLogsData2 = await query19.find();

			_adLogsData2.map(log => {
				data.totalRevenueToday += log.get('adRevenue')
			})
			data.totalRevenueToday = data.totalRevenueToday.toFixed(2);


			return data;
		},
		{}
	)
}



