import React, { useState, useEffect, Component, useRef } from "react";
import { Flex, Text, Button, Input, Textarea, Select, Switch, Tag, Image, useToast } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

import { useVideos, useCategories, useEditVideo, useDeleteVideo } from "../../hooks";

import AlbumDetails from "./album-details";
import VideoDetails from "./video-details";

export default function MultiLangDetails() {
	const { id } = useParams();
	const { data: videos, isLoading } = useVideos();
	const [data, setData] = useState();


	useEffect(() => {
		if (isLoading == false) {
			videos.map(a => {
				if (a.type == "multiLangGroup") {
					const groupIndex = a.group.findIndex(e => e.id == id);
					if (groupIndex >= 0) {
						setData({
							...a.group[groupIndex],
							live: a.live
						});
					}
				}
			})
		}
	},[isLoading])

	if (!data) {
		return;
	}

	if (data.type == "album") {
		return <AlbumDetails prefetchedData={data} />
	}

	if (data.type == "video") {
		return <VideoDetails prefetchedData={data} />
	}

	return (
		<Flex flex="1" direction="column" w="100%" h={window.innerHeight}>
			
		</Flex>
	)
}