import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";
import { supabase } from '../utils'


export const useCategories = (type) => {
	return useQuery(['categories', type],
		async () => {
			const { data } = await supabase.from('Categories').select('*').eq('type', type);
			data.sort((a, b) => a.order - b.order)
			
			return data.map(category => {
				return {
					id:category.id,
					order: category.order,
					type: category.type,
					fullTitle: {
						"english": category.title,
						"arabic": category.title_arabic,
						"persian": category.title_persian
					}
				}
			})
		},
		{}
	)
}

export const useAddCategory = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		try {
			await Promise.all(data.categories.map(async (cat) => {
				if (cat.localId) {
					await supabase.from('Categories').insert({
						"type": cat.type,
						"title": cat.fullTitle.english,
						"title_arabic": cat.fullTitle.arabic,
						"title_persian": cat.fullTitle.persian,
						"order": cat.order
					})
				} else {
					if (cat.deleted == true) {
						const { data: albums } = await supabase.from('VideoAlbums').select('*').eq('category', cat.id);
						if (albums.length > 0) {
							await Promise.all(albums.map(async album => {
								await supabase
									.from('VideoAlbums')
									.update({ 
										category: null
									})
									.eq('id', album.id)
							}))
						}

						const { data: videos } = await supabase.from('Videos').select('*').eq('category', cat.id);
						if (videos.length > 0) {
							await Promise.all(videos.map(async vid => {
								await supabase
									.from('Videos')
									.update({ 
										category: null
									})
									.eq('id', vid.id)
							}))
						}

						await supabase
							.from('Categories')
							.delete()
							.eq('id', cat.id)						
					} else {
						await supabase
							.from('Categories')
							.update({ 
								order: cat.order,
								title: cat.fullTitle.english,
								title_arabic: cat.fullTitle.arabic,
								title_persian: cat.fullTitle.persian,
								updated_at: new Date()
							})
							.eq('id', cat.id)
					}
				}
			}))
		}
		catch(error) {
			console.log(error.message)
		}

		queryClient.refetchQueries(['categories', data.type]);
	}, {
		...options,
	})
}



