import React, { useState, useEffect, Component, useRef } from "react";
import { Flex, Text, Button, Input, Textarea, Select, Switch, Slider, SliderTrack, SliderFilledTrack, SliderThumb, useToast, Menu, MenuButton, IconButton, MenuList, MenuItem  } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { useAudioPlayer } from 'react-use-audio-player';
import ReactMarkdown from "react-markdown";
import { nanoid } from "nanoid";

import { Header } from "../includes";
import { useDuaaContent, useReciters, useEditDuaaContent } from "../hooks";
import { isEmpty } from "../utils";

export default function DuaaDetails() {
	const [loading, setLoading] = useState(false);
	const progressRef = useRef(0);
	const copiedRef = useRef()
	const toast = useToast();

	const { id } = useParams();
	const { data, isLoading } = useDuaaContent({ id });
	const { mutateAsync: updateDuaa } = useEditDuaaContent();

	const { data: reciters = [] } = useReciters();
	const { load, togglePlayPause, playing, paused, isReady, play: onPlayAudio, pause, duration, getPosition, seek, error } = useAudioPlayer();
	const [progress, setProgress] = useState(0);

	const [language, setLanguage] = useState("english");
	const [title, setTitle] = useState({
		"english":"",
		"arabic":"",
		"persian":""
	})
	const [description, setDescription] = useState({
		"english":"",
		"arabic":"",
		"persian":""
	})
	const [slug, setSlug] = useState("")
	const [source, setSource] = useState("");
	const [reciter, setReciter] = useState("");
	const [newContent, setNewContent] = useState([]);
	const [oldContent, setOldContent] = useState([])
	const [bgColor, setBgColor] = useState()
	const [isActive, setIsActive] = useState()

	const [youtubePreview, setYoutubePreview] = useState(false);
	const [sourcePreview, setSourcePreview] = useState(false);
	const [requiresSound, setRequiresSound] = useState(false);

	useEffect(() => {
		if (data) {
			setTitle({
				"english":data.duaa_ziyara_id.title,
				"arabic":data.duaa_ziyara_id.title_arabic,
				"persian": data.duaa_ziyara_id.title_persian
			})
			setDescription({
				"english":data.duaa_ziyara_id.description,
				"arabic": data.duaa_ziyara_id.description_arabic,
				"persian": data.duaa_ziyara_id.description_persian
			})
			setSlug(data.duaa_ziyara_id.slug)
			setBgColor(data.duaa_ziyara_id.bgColor);
			setReciter(data.sound_id.reciter_id)
			setNewContent(data.content);
			setOldContent(data.oldContent);
			setIsActive(data.duaa_ziyara_id.active);
			setSource(data.duaa_ziyara_id.source);

			setRequiresSound(data.duaa_ziyara_id.requiresSound)
		}
	},[isLoading])

	function handleTextChange(type, value) {
		if (type == "title") {
			let _title = { ...title };
			_title[language] = value;
			setTitle(_title);	
		}
		if (type == "description") {
			let _description = { ...description };
			_description[language] = value;
			setDescription(_description);	
		}		
	}

	function onPlay(e, seekTime) {
		load(data.sound_id.sound, {
			autoplay: true,
			html5: true,
			format: 'mp3',
			onload: () => {
				if (seekTime) {
					seek(seekTime)
				}
			}
		});

	    setInterval(() => {
	    	progressRef.current = getPosition();
	    	setProgress(getPosition());
	    }, 100);

	}

	function onPause() {
		if (isReady) {
			togglePlayPause();
		}
	}

	function onContentChange(id, type, value) {
		let _newContent = [...newContent];
		let index = _newContent.findIndex(e => e.id == id);

		if (index >= 0) {
			_newContent[index][type] = value;
			setNewContent(_newContent);
		}
	}

	function onAddContentBox() {
		let _newContent = [...newContent];
		_newContent.push({
			"id": nanoid(),
			"end": 0,
			"seek":0,
			"start":0,
			"words": [],
			"arabic":"",
			"english":"",
			"arglish":"",
			"persian":""
		})
		setNewContent(_newContent);
	}

	function onClearEmptyContent() {
		let _newContent = [...newContent];
		let filtered = _newContent.filter(e => isEmpty(e.english) && isEmpty(e.arabic));

		filtered.map((a) => {
			let index = _newContent.findIndex(e => e.id == a.id);
			if (index >= 0) {
				_newContent.splice(index, 1);
			}
		})

		setNewContent(_newContent)
	}


	function onContentDelete(id) {
		if (window.confirm("Are you sure?") == true) {
			let _newContent = [...newContent];
			let index = _newContent.findIndex(e => e.id == id);
			if (index >= 0) {
				_newContent.splice(index, 1);
				setNewContent(_newContent);
			}
		}
	}

	function onOldContentDelete(id) {
		let _oldContent = [...oldContent];
		let index = _oldContent.findIndex(e => e.id == id);
		if (index >= 0) {
			_oldContent.splice(index, 1);
			setOldContent(_oldContent);
		}
	}

	function onSeek(time) {
		if (isReady == false) {
			onPlay(null, time);
		} else {
			seek(time);
			if (paused) {
				onPlayAudio()
			}	
		}
	}

	function onCopyText(text) {
	  	navigator.clipboard.writeText(text)
	    .then(() => {
			toast({
				status:"success",
				title: "Copied!",
				position: 'top',
			})
	    })
	}

	function onCopyOld(obj) {
		if (obj.arglish && obj.arabic) {
			copiedRef.current = obj;
			toast({
				status:"success",
				title: "Copied!",
				position: 'top',
			})
		}
	}

	function onPasteNew(id) {
		if (copiedRef && copiedRef.current) {
			let _newContent = [...newContent];
			let index = _newContent.findIndex(e => e.id == id);
			if (index >= 0) {
				_newContent[index].arabic = copiedRef.current.arabic;
				_newContent[index].english = copiedRef.current.english;
				_newContent[index].arglish = copiedRef.current.arglish;
				setNewContent(_newContent);
			}
		}
	}

	function onSetPreset(id, preset) {
		let _newContent = [...newContent];
		let index = _newContent.findIndex(e => e.id == id);
		if (index >= 0) {
			_newContent[index].arabic = preset.arabic;
			_newContent[index].english = preset.english;
			_newContent[index].arglish = preset.arglish;
			setNewContent(_newContent);
		}
	}

	function moveOldContent() {
		let _newContent = [];
		oldContent.map(cont => {
			_newContent.push({
				arabic:cont.arabic,
				english: cont.english,
				arglish: cont.arglish,
				id: cont.id,
				seek: 0,
				end: 0,
				start: 0
			})
		})
		setNewContent(_newContent);
	}

	async function handleSubmit() {
		const errors = validate({ title, bgColor, slug, source });
		if (errors.length > 0) {
			toast({
				status:"error",
				title:errors.join(", "),
				position: 'top',
			})
			return;
		}
		setLoading(true);

		try {
			await updateDuaa({
				ziyaraId: id,
				contentId: data.id,
				soundId: data.sound_id.id,
				title,
				description,
				slug,
				bgColor,
				isActive,
				reciter,
				source,
				requiresSound,
				content: newContent
			})
			toast({
				status:"success",
				title: "Duaa saved succesfully!",
				position: 'top',
			})

			window.location.reload();
		}
		catch(error) {
			toast({
				status:"error",
				title: error.message,
				position: 'top',
			})
		}

		setLoading(false);
	}

	if (!data) {
		return
	}

	const languges = ['English', 'Arabic', 'Persian'];

	return (
		<Flex flex="1" direction="column" w="100%" h={window.innerHeight}>
			<Header />
			<Flex p="12px 20px" borderBottom="1px solid" borderBottomColor="light">
				<Flex as={Link} to="/duaa" fontSize="12px" align="center" color="primary">
					<i className="fa-solid fa-arrow-left"></i>
					<Text pl="5px" variant="link">Back to Duaa</Text>
				</Flex>
				<Flex align="center" justify="center" flex="1">
					<Button onClick={onClearEmptyContent} variant="secondary">Clear empty rows</Button>
					<Button ml="10px" onClick={moveOldContent} variant="secondary">Copy over content</Button>
				</Flex>
				<Text textAlign="right" variant="subtitle" fontSize="10px">{id}</Text>
			</Flex>	
			<Flex flex="1" direction="row" w="100%" pb="150px">
				<Flex w="330px" h="100%" p="10px" borderRight="1px solid #f3f3f3" direction="column">
					<Flex mb="20px">
						{
							languges.map(lang => (
								<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 10px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
									<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="13px">{lang}</Text>
								</Flex>
							))
						}
					</Flex>
					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Title</Text>
							<Text color="red">*</Text>
						</Flex>
						<Input
							mt="3px"
							value={title[language]}
							onChange={(e) => handleTextChange('title', e.target.value)}
							fontWeight="500"
							h="45px"
							p="10px"
							fontSize="15px"
						/>
					</Flex>
					<Flex direction="column" mb="15px" w="100%">
						<Flex>
							<Text mr="4px" variant="subtitle">Description</Text>
							<Text color="red">*</Text>
						</Flex>
						<Textarea
							mt="3px"
							value={description[language]}
							onChange={(e) => handleTextChange('description', e.target.value)}
							fontWeight="500"
							resize="none"
							rows={5}
							p="10px"
							fontSize="13px"
						/>
					</Flex>

					<Flex mb="15px" >
						<Flex direction="column" w="100%" mr="5px" flex="1">
							<Flex>
								<Text mr="4px" variant="subtitle">Slug</Text>
								<Text color="red">*</Text>
							</Flex>
							<Input
								mt="3px"
								value={slug}
								onChange={(e) => setSlug(e.target.value)}
								fontWeight="500"
								h="45px"
								p="10px"
								fontSize="14px"
							/>
						</Flex>

						<Flex direction="column" w="100%" flex="1">
							<Flex>
								<Text mr="4px" variant="subtitle">Youtube ID</Text>
								<Text flex="1" color="red">*</Text>

								<Text onClick={() => setYoutubePreview(!youtubePreview)} fontSize="13px" cursor="pointer" color="primary">{youtubePreview ? "Hide" : "Preview"}</Text>
							</Flex>
							<Input
								mt="3px"
								value={data.sound_id.youtube_id}
								fontWeight="500"
								h="45px"
								p="10px"
								fontSize="14px"
							/>
						</Flex>
					</Flex>

					{
						youtubePreview && (
							<iframe width="320" height="250" style={{ marginBottom: 10 }} src={"https://www.youtube.com/embed/" + data.sound_id.youtube_id} title="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
						)
					}

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">BG Color</Text>
							<Text color="red">*</Text>
						</Flex>
						<Flex direction="row" mt="3px" align="center">
							<Input
								value={bgColor}
								onChange={(e) => setBgColor(e.target.value)}
								fontWeight="500"
								h="45px"
								p="10px"
								fontSize="14px"
							/>
							<Flex w="35px" h="35px" borderRadius="5px" bg={bgColor} ml="5px"></Flex>
						</Flex>
					</Flex>

					<Flex direction="column" w="100%" mb="15px">
						<Flex>
							<Text mr="4px" variant="subtitle">Source</Text>
							<Text flex="1" color="red">*</Text>

							<Text onClick={() => setSourcePreview(!sourcePreview)} fontSize="13px" cursor="pointer" color="primary">{sourcePreview ? "Hide" : "Preview"}</Text>
						</Flex>
						<Input
							value={source}
							onChange={(e) => setSource(e.target.value)}
							fontWeight="500"
							h="45px"
							p="10px"
							fontSize="14px"
						/>
					</Flex>


					<Flex direction="column" mb="15px" w="100%">
						<Flex>
							<Text mr="4px" variant="subtitle">Reciter</Text>
							<Text color="red">*</Text>
						</Flex>
						<Select mt="3px" fontWeight="500" fontSize="14px" onChange={(e) => setReciter(e.target.value)} value={reciter}>
							{
								reciters.map(recit => (
									<option value={recit.id}>{recit.name}</option>
								))
							}
						</Select>
					</Flex>					

					<Flex onClick={isReady ? onPause : onPlay} direction="column" w="100%" mb="10px" cursor="pointer">
						<Flex>
							<Text mr="4px" variant="subtitle">Sound</Text>
							<Text color="red">*</Text>
						</Flex>
						<Flex align="center" mt="3px">
							<Input
								value={data.sound_id.sound}
								fontWeight="500"
								h="45px"
								p="10px"
								fontSize="12px"
							/>
							<Flex ml="10px" w="30px" h="30px" align="center" justify="center" fontSize="23px" color="primary">
								{
									playing ?
									<div key="2">
										<i className="fa-solid fa-circle-pause"></i>
									</div>
									:
									<div key="3">
										<i className="fa-solid fa-circle-play"></i>
									</div>									
								}
							</Flex>
						</Flex>
					</Flex>

					<Flex mt="20px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Live</Text>
						<Switch isChecked={isActive} onChange={(e) => setIsActive(e.target.checked)} colorScheme="green" size='md' />
					</Flex>

					<Flex mt="20px" mb="35px" align="center" justify="center">
						<Text flex="1" variant="subtitle">Requires Sound</Text>
						<Switch isChecked={requiresSound} onChange={(e) => setRequiresSound(e.target.checked)} colorScheme="green" size='md' />
					</Flex>					

					<Flex position="fixed" zIndex={2} bottom="15px" pr="10px" w="320px" borderTop="1px solid" borderTopColor="light" pt="15px">
						<Button isLoading={loading} onClick={handleSubmit} variant="secondary" p="13px 15px" fontSize="13px" w="100%">Save details</Button>
					</Flex>					
				</Flex>

				<NewContent 
					type="New" 
					content={newContent} 
					progress={progress}
					progressRef={progressRef}
					onContentChange={onContentChange} 
					onContentDelete={onContentDelete} 
					onSeek={onSeek}
					onAddContentBox={onAddContentBox}
					onSetPreset={onSetPreset}
					onPasteNew={onPasteNew}
				/>

				
				{
					sourcePreview ?
					<Flex flex="1" borderRight="1px solid #f3f3f3" direction="column" overflow="scroll" h="990px">
						<iframe src={source} style={{ width:"100%", height:"100%"}} />
					</Flex>
					:
					<OldContent 
						type="Old" 
						content={oldContent} 
						onContentDelete={onOldContentDelete} 
						onCopyText={onCopyText}
						onCopyOld={onCopyOld}
					/>
				}

			</Flex>

			<Flex position="fixed" zIndex={1} bottom="15px" w="100%" align="center" justify="center">
				<Flex onClick={isReady ? onPause : onPlay} cursor="pointer" w="50px" h="50px" bg="primary" borderRadius="30px" align="center" justify="center" fontSize="20px" color="white">
					{
						playing ?
						<div key="2">
							<i className="fa-solid fa-pause"></i>
						</div>
						:
						<div key="3">
							<i className="fa-solid fa-play"></i>
						</div>									
					}					
				</Flex>
			</Flex>
		</Flex>
	)
}

function NewContent({ content, type, onContentChange, onContentDelete, progress, progressRef, onSeek, onAddContentBox, onSetPreset, onPasteNew }) {
	return (
		<Flex flex="1" borderRight="1px solid #f3f3f3" p="10px" direction="column" overflow="scroll" h="990px">
			<Flex direction="row" mb="10px" align="center">
				<Text flex="1" fontSize="14px">{type} Content</Text>
				<Text fontSize="13px" opacity="0.5">{content.length} verses</Text>
			</Flex>
			{
				content.slice(0, 300).map((cont, i) => (
					<NewContentRow 
						key={i} 
						isActive={progress > cont.start && progress < cont.end}
						type={type} 
						cont={cont} 
						arabic={cont.arabic}
						english={cont.english}
						arglish={cont.arglish}
						onSetTime={(e) => onContentChange(cont.id, e, progressRef.current || 0)}

						id={cont.id}
						startTime={cont.start}
						endTime={cont.end}
						onSetPreset={onSetPreset}
						total={content.length}
						onPasteNew={() => onPasteNew(cont.id)}

						onSeek={() => onSeek(cont.start)}
						i={i + 1} 
						onContentChange={onContentChange} 
						onDelete={onContentDelete} 
					/>
				))
			}
			<Flex align="center" justify="center" mt="10px" mb="20px">
				<Button onClick={onAddContentBox} variant="dark" p="13px" fontSize="15px" borderRadius="30px">Add Row</Button>
			</Flex>
		</Flex>
	)
}

function OldContent({ content, type, onContentChange, onContentDelete, onCopyText, onCopyOld }) {
	return (
		<Flex flex="1" p="10px" direction="column" overflow="scroll" h="990px">
			<Flex direction="row" mb="10px" align="center">
				<Text flex="1" fontSize="14px">Old Content</Text>
				<Text fontSize="13px" opacity="0.5">{content.length} verses</Text>
			</Flex>
			{
				content.slice(0, 300).map((cont, i) => (
					<NewContentRow 
						key={i} 
						type={type} 
						total={content.length} 
						onCopyText={onCopyText} 
						cont={cont} 
						i={i + 1} 
						onContentChange={onContentChange} 
						onDelete={onContentDelete}
						onCopyOld={onCopyOld}
					 />
				))
			}
		</Flex>
	)
}

class NewContentRow extends Component {
    shouldComponentUpdate(nextProps) {
    	if (this.props.type == "Old") {
    		return false;
    	}

    	if (this.props.isActive != nextProps.isActive || this.props.i != nextProps.i || this.props.total != nextProps.total || this.props.id != nextProps.id || this.props.english != nextProps.english || this.props.arglish != nextProps.arglish || this.props.arabic != nextProps.arabic || this.props.startTime != nextProps.startTime || this.props.endTime != nextProps.endTime) {
    		return true;
    	}
       
        return false;
    }

    render() {
        const { cont, type, onContentChange, onDelete, isActive, onSeek, onSetTime, onCopyText, onSetPreset, i, total, onPasteNew, onCopyOld } = this.props;
        
        return (
            <Flex direction="column" mb="15px" border="2px solid" borderColor={type == "New" && isActive ? "primary" : "#f3f3f3"} p="12px" borderRadius="10px">
                <Input
                    type="text"
                    value={cont.arabic}
                    onChange={type == "Old" ? (e) => console.log() : (e) => onContentChange(cont.id, 'arabic', e.target.value)}
                    border="none"
                    variant="arabic"
                    textAlign="right"
                    fontSize="27px"
                    fontWeight="600"
                    onClick={onCopyText ? () => onCopyText(cont.arabic) : null}
                />
                <Input
                    type="text"
                    value={cont.arglish}
                    onChange={type == "Old" ? (e) => console.log() : (e) => onContentChange(cont.id, 'arglish', e.target.value)}
                    border="none"
                    placeholder="arglish"
                    textAlign="right"
                    fontSize="15px"
                    onClick={onCopyText ? () => onCopyText(cont.arglish) : null}
                />	
                <Input
                    type="text"
                    value={cont.english}
                    onChange={type == "Old" ? (e) => console.log() : (e) => onContentChange(cont.id, 'english', e.target.value)}
                    border="none"
                    textAlign="right"
                    fontSize="17px"
                    mb="20px"
                    onClick={onCopyText ? () => onCopyText(cont.english) : null}
                />	

                <Flex direction="row" mt="10px" align="center" borderTop="1px solid #f3f3f3" pt="10px">
                   {
                   	type == "Old" ?
                    <Flex mr="10px" onClick={() => onCopyOld(cont)} w="25px" h="25px" bg="#f3f3f3" borderRadius="30px" align="center" justify="center" color="gray" fontSize="13px" cursor="pointer">
                        <div>
                            <i className="fa-solid fa-copy"></i>
                        </div>
                    </Flex>  
                    :
                    null
                   }

                    <Flex onClick={() => onDelete(cont.id)} w="25px" h="25px" bg="#f3f3f3" borderRadius="30px" mr="10px" align="center" justify="center" color="gray" fontSize="13px" cursor="pointer">
                        <div>
                            <i className="fa-solid fa-trash"></i>
                        </div>
                    </Flex>

                    {type == "New" ? (
                        <>
                    		<PresetMenu onSetPreset={(e) => onSetPreset(cont.id, e)} />
                    		<Text fontSize="12px" fontWeight="600" opacity="0.4">{i}/{total}</Text>
                        	<Flex flex="1"></Flex>

		                    <Flex onClick={onPasteNew} w="25px" h="25px" bg="#f3f3f3" mr="10px" borderRadius="30px" align="center" justify="center" color="gray" fontSize="13px" cursor="pointer">
		                        <div>
		                            <i className="fa-solid fa-paste"></i>
		                        </div>
		                    </Flex>   
                            <Text fontSize="13px" opacity="0.5" mr="5px">Start:</Text>
                            <Button onClick={() => onSetTime('start')} variant="secondary" p="5px" fontWeight="500" mr="10px">{cont.start.toFixed(2)}</Button>

                            <Text fontSize="13px" opacity="0.5" mr="5px">End:</Text>
                            <Button onClick={() => onSetTime('end')} variant="secondary"p="5px" fontWeight="500">{cont.end.toFixed(2)}</Button>

		                    <Flex onClick={onSeek} w="25px" h="25px" bg="#f3f3f3" borderRadius="30px" ml="10px" align="center" justify="center" color="gray" fontSize="13px" cursor="pointer">
		                        <div>
		                            <i className="fa-solid fa-play"></i>
		                        </div>
		                    </Flex>    
                        </>
                    ) : (
                        <Flex flex="1" align="center" justify="flex-start">                        	
                        	<Text textAlign="right" flex="1" fontSize="12px" fontWeight="600" opacity="0.4">{i}/{total}</Text>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        );
    }
}


class PresetMenu extends Component {
    shouldComponentUpdate(nextProps) {
        return false;
    }
    render(){
    	let selects = [{
    		"title": "Bismillahi",
    		// "arabic":"بِسْمِ ٱللَّهِ ٱلرَّحْمٰنِ ٱلرَّحِيمِ",
    		"arabic":"بِسْمِ ٱللَّهِ ٱلرَّحْمَنِ ٱلرَّحِيمِ",
    		"english":"In the Name of Allah, the All-beneficent, the All-merciful.",
    		"arglish":"bismillahi alrrahmani alrrahimi"
    	}, {
    		"title":"Allahumma salli",
    		"arabic":"اللَّهُمَّ صَلِّ عَلَى مُحَمَّدٍ وَآلِ مُحَمَّدٍ",
    		"english":"O Allah, bless Muhammad and the family of Muhammad",
    		"arglish":"Allahumma salli 'ala Muhammad wa Aali Muhammad."
    	}, {
    		"title":"Aalihi At-Tahireen",
    		"arabic":"وَصَلِّ ٱللَّهُمَّ عَلَىٰ مُحَمَّدٍ وَآلِهِ ٱلطَّاهِرِينَ",
    		"english":"May God bless Muhammad and his pure family",
    		"arglish":"Wa salli Allahumma ‘ala Muhammad wa Aalihi At-Tahireen"
    	},{
    		"title":"Arham ar-Rahimeen",
    		"arabic":" يَا أَرْحَمَ الرَّاحِمِينَ",
    		"english":"O Most Merciful of the merciful",
    		"arglish":"Ya Arham ar-Rahimeen"
    	}]

		return (
			<Menu>
				<MenuButton>
	                <Flex  w="25px" h="25px" bg="#f3f3f3" borderRadius="30px" mr="10px" align="center" justify="center" color="gray" fontSize="13px" cursor="pointer">
	                    <div>
	                        <i className="fa-solid fa-bars"></i>
	                    </div>
	                </Flex>
				</MenuButton>
				<MenuList>
					{
						selects.map((sel, i) => (
							<MenuItem onClick={() => this.props.onSetPreset(sel)} key={i}>{sel.title}</MenuItem>

						))
					}
				</MenuList>
			</Menu>
		)    	
    }
}




function validate({ title, slug, bgColor, source }) {
	let errors = [];

	if (isEmpty(title.english) || isEmpty(title.arabic) || isEmpty(title.persian)) {
		errors.push('Title')
	}

	if (isEmpty(slug)) {
		errors.push('Slug')
	}
	if (isEmpty(bgColor)) {
		errors.push('BG Color')
	}
	if (isEmpty(source)) {
		errors.push('Source')
	}

	return errors;
}


































