import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";
import { supabase } from '../utils'

export const useVideoAlbums = () => {
	return useQuery(['video-albums'],
		async () => {
			const { data: albums } = await supabase.from('VideoAlbums').select('*');
			return albums;
		},
		{}
	)
}

export const useAddVideoAlbum = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		await supabase.from('VideoAlbums').insert({
			"live": false,
			"order": data.order,
			"title": data.title,
			"language": data.language,
			"category": data.category,
		})
		queryClient.refetchQueries(['videos']);
	}, {
		...options,
	})
}

export const useEditVideoAlbum = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {
		await supabase
			.from('VideoAlbums')
			.update({ 
				title: data.title,
				description: data.description,
				category: data.category,
				language: data.language,
				live: data.live,
			})
			.eq('id', data.id)		

		queryClient.refetchQueries(['videos']);
	}, {
		...options,
	})
}

export const useDeleteAlbum = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const { data: videos } = await supabase.from('Videos').select('*').eq('album', data.id);
		if (videos.length > 0) {
			await Promise.all(videos.map(async vid => {
				await supabase
					.from('Videos')
					.delete()
					.eq('id', vid.id);
			}))
		}
		await supabase
			.from('VideoAlbums')
			.delete()
			.eq('id', data.id)	

		queryClient.refetchQueries(['videos']);
	}, {
		...options,
	})
}







