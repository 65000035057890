import { useState, useRef, useEffect } from "react";
import { Flex, Text, Button, Image } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react'; 
import { Link } from "react-router-dom";

import { Header, AddCategoryModal, AddVideoModal, VideoBox, EditVideoModal, CreateAlbumModal, AlbumBox, EditAlbumModal, AddMultiLanguage } from "../includes";

import { useDuaa } from "../hooks";
import { tableTheme } from "../includes/theme";
import { useCategories, useVideos, useVideoAlbums, useEditVideoOrder } from "../hooks";

export default function Videos() {
	const gridRef = useRef();

	const { data: categories } = useCategories('video');
	const { data: albums } = useVideoAlbums();
	const { data: videos, isLoading } = useVideos();
	const { mutateAsync: updateOrder } = useEditVideoOrder();

	const [addCategory, setAddCategory] = useState(false);
	const [addVideo, setAddVideo] = useState(false);
	const [addAlbum, setAddAlbum] = useState(false);
	const [addMultiLanguage, setAddMultiLanguage] = useState(false);

	const [rowData, setRowData] = useState();
    const [colDefs, setColDefs] = useState([
        { 
        	field: "id", 
        	headerName: "ID", 
        	flex: 1 ,
        	rowDrag: true
        },
        { 
        	field: "title", 
        	headerName: "Name",
        	filter: true,
        	flex: 3,
        	cellRenderer: ({ data }) => {
        		return (
					<Flex  h="100%" align="center">
						{/*{
							data.type == "album" && data.videos.length > 0 ?
							<Image src={data.videos[0].image} w="62px" objectFit="cover" h="35px" alt="Video Image" borderRadius="5px" />
							: data.image ?
							<Image src={data.image} w="62px" h="35px" objectFit="cover" alt="Video Image" borderRadius="5px" />
							: data.type == "multiLangGroup" && data.group.length > 0 ?
							data.group[0].type == "album" && data.group[0].videos.length > 0 ?
							<Image src={data.group[0].videos[0].image} objectFit="cover" w="62px" h="35px" alt="Video Image" borderRadius="5px" />
							:
							<Image src={data.group[0].image} w="62px" objectFit="cover" h="35px" alt="Video Image" borderRadius="5px" />
							:
							null
						}*/}
						<Text pl="13px" fontWeight="400" fontSize="15px">{data.title}</Text>
					</Flex>
        		)
        	}
        },
        { 
        	field: "description", 
        	filter: true,
        	flex: 3 
        },
        { 
        	field: "type",
        	filter: true,
        	flex: 1 
        },
		{ 
        	field: "language",
        	filter: true,
        	flex: 1 
        },
        { 
        	field: "live", 
        	filter: true,
        	flex: 1 
        },
        { 
        	field: "order", 
        	filter: true,
        	flex: 1 
        },

		{
        	field: 'button',
        	headerName: "",
        	flex: 2,
        	cellRenderer: ({ data }) => {
        		if (data.type == "multiLangGroup") {
        			return (
        				<Flex flex="1" align="center" justify="flex-end" h="100%">
        					{
        						data.group.map(group => (
        							<Button as={Link} to={`/multi-lang-group/${group.id}`} ml="10px" borderRadius="5px" textTransform="capitalize" variant={group.language == "english" ? "primary" : "secondary"} fontWeight="500" p="7px 12px">{group.language}</Button>
        						))
        					}
        				</Flex>
        			)
        		}
        		return (
	        		<Flex flex="1" align="center" justify="flex-end" h="100%">
	        			<Button as={Link} to={`/${data.type}/${data.id}`} variant="outline" fontWeight="500" p="7px 12px">Edit</Button>
	        		</Flex>
        		)
        	}
        }
    ]);

    useEffect(() => {
    	if (isLoading == false) {
    		setRowData(videos);
    	}
    },[isLoading])

    function onRowDragEnd(e) {
    	if (!gridRef.current) return;

    	const gridApi = gridRef.current.api;
        const newRowData = [];

        gridApi.forEachNode((node, index) => {
            newRowData.push({ ...node.data, order: index + 1 });
        });
        setRowData(newRowData);
        updateOrder(newRowData);
    }

	return (
		<Flex flex="1" direction="column" h={window.innerHeight} w="100%">
			<Header />
			<Flex flex="1" direction="column" p="30px 40px" w="100%">
				<Flex align="center" justify="center" mb="10px">
					<Flex flex="1" direction="column">
						<Text mb="7px" variant="title">Videos</Text>
					</Flex>

					<Button onClick={() => setAddCategory(true)} variant="secondary" borderRadius="30px" mr="10px" fontSize="14px" p="12px 15px">Edit categories</Button>
					<Button onClick={() => setAddMultiLanguage(true)} variant="secondary" borderRadius="30px" mr="10px" fontSize="14px" p="12px 15px">Create Multi Language</Button>
					<Button onClick={() => setAddAlbum(true)} variant="secondary" borderRadius="30px" mr="10px" fontSize="14px" p="12px 15px">Create album</Button>
					<Button onClick={() => setAddVideo(true)} variant="primary" borderRadius="30px" fontSize="14px" p="12px 15px">Add video</Button>

				</Flex>

			    <Flex flex="1" borderRadius="10px" w="100%" direction="column">
			        <AgGridReact
			        	ref={gridRef}
			            rowData={rowData}
			            theme={tableTheme}
			            columnDefs={colDefs}
			            rowDragManaged
			            onRowDragEnd={onRowDragEnd}
			        />
			    </Flex>
			</Flex>
			{
				addCategory && <AddCategoryModal totalCategories={categories} type="video" onClose={() => setAddCategory(false)} />
			}
			{
				addAlbum && <CreateAlbumModal categories={categories} totalAlbums={rowData.length} onClose={() => setAddAlbum(false)} />
			}
			{
				addVideo && <AddVideoModal albums={albums} totalVideos={rowData.length} onClose={() => setAddVideo(false)} />
			}
			{
				addMultiLanguage && <AddMultiLanguage totalRows={rowData.length} videos={rowData.filter(e => e.type == "video")} albums={rowData.filter(e => e.type == "album")} onClose={() => setAddMultiLanguage(false)} />
			}
		</Flex>
	)
}








